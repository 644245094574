body {
  background-image: none;
}

img {
  display: block;
  margin: 0 auto;
}

hr {
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.copyright {
  font-size: 0.8rem;
  color: #999;
}

.lead {
  font-size: 1.2rem;
}

.homepage-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 24px;
  div {
    flex: 0 0 5em;
  }
  a {
    flex: 1;
  }
}

.page-link {
  cursor: pointer;
}

.post-tags {
  margin-top: 15px;
  text-align: center;
}

.post-tags a .tag-name {
  text-transform: none;
}

.post-title {
  font-family: 'Raleway', sans-serif;
  a {
    color: $link-color !important;
  }
}

.share-link-line {
  text-align: center;
  font-size: 18px;
  color: #777;
}

// Full
@media (min-width: $large-breakpoint) {
  #sidebar {
    top: 0;
    text-align: center !important;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  #sidebar-icon-links {
    justify-content: center;
  }
  .container {
    padding: 2rem 3rem 0;
  }
}

// Collapsed
@media (max-width: $large-breakpoint) {
  #logo {
    display: none;
  }
  #sidebar {
    hr {
      display: none;
    }
  }
  .post-title {
    a {
      color: #ffffff !important;
    }
  }
}

.home {
  .site-title {
    font-size: 2rem;
  }
}

#sidebar {
  .site-title {
    font-family: 'Raleway', sans-serif;
    a {
      font-size: 2.5rem;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
